import { IWidgetOptions, Widgets, WidgetSize } from "./widget/widget.models";

export namespace FlexDashboardConstants {

	export const DefaultDashboard: IWidgetOptions[] = [
		{
			size: WidgetSize.Three,
			type: Widgets.DealsByStatus
		},
		{
			size: WidgetSize.Two,
			type: Widgets.Notifications
		},
		{
			size: WidgetSize.One,
			type: Widgets.RecentActivity
		},
		{
			size: WidgetSize.One,
			type: Widgets.Favorites
		}
	];

	export const AssignedConflictsWidget: IWidgetOptions = {
		size: WidgetSize.Two,
		type: Widgets.AssignedConflicts
	};

}
