<div class="d-flex align-items-center">
	<div class="brand text-center">
		<a [routerLink]="linkHelper.home()" title="Rightsline">
			<rl-logo [style.height.px]="45"></rl-logo>
		</a>
	</div>
	<div class="div-logo ps-3">
		<h3 class="mb-0"><img *ngIf="(divImageUrl$ | async) as divImageUrl; else defaultDivName" [src]="divImageUrl"
				title="{{divName$ | async}}" />
		</h3>
	</div>
	<ng-template #defaultDivName>
		{{divName$ | async}}
	</ng-template>
	<div *ngIf="!shouldShowEnvironment" class="environment text-center d-none d-md-block"
		title="{{ sessionService.divId$ | async }}" aria-hidden="true">
		{{environmentName}}
	</div>
</div>
<div class="d-flex align-items-center">
	<div class="tools d-flex justify-content-end align-items-center">
		<div class="align-items-center me-1" role="search">
			<rl-universal-search></rl-universal-search>
		</div>
		<div class="align-items-center" ngbDropdown placement="bottom-right" autoClose="outside"
			#quickLinksDropdown="ngbDropdown">
			<div *ngIf="(sessionService.isValid$ | async)">
				<button class="btn btn-icon hide-caret" id="favoritesDropdown" ngbDropdownToggle role="button"
					data-cy="FavoritesDropdownButton" title="Quick Links">
					<i class="fa-star mx-1" [class.far]="!(isFavorite$ | async)" [class.fas]="isFavorite$ | async"
						[class.text-warning]="isFavorite$ | async"></i>
				</button>
				<rl-header-favorites ngbDropdownMenu aria-labelledby="favoritesDropdown" class="py-0">
				</rl-header-favorites>
			</div>
		</div>

		<div ngbDropdown placement="bottom-right" autoClose="outside" #notificationsDropdown="ngbDropdown">
			<ng-container *ngIf="(sessionService.isValid$ | async)">
				<button ngbDropdownToggle role="button" class="btn btn-icon hide-caret"
					data-cy="NotificationsDropdownButton" title="Notifications">
					<span *ngIf="(count$ | async) as count; else empty" class="mx-1">
						<i class="far fa-bell"></i>
						<i class="fa fa-circle danger"></i>
						<span class="fa-stack-1x note--count">{{count < 100 ? count : '99+' }}</span>
						</span>
				</button>
				<rl-header-notifications ngbDropdownMenu class="py-0"></rl-header-notifications>
			</ng-container>
		</div>
		<ng-template #empty>
			<i class="far fa-bell mx-1"></i>
		</ng-template>
	</div>
	<div [ngSwitch]="(sessionService.isValid$ | async)" class="profile mx-2">
		<div *ngSwitchCase="true">
			<rl-profile-dropdown></rl-profile-dropdown>
		</div>
		<div *ngSwitchDefault="">
			<a [routerLink]="linkHelper.login()">Login</a>
		</div>
	</div>
</div>